<template lang="pug">
v-tab-item(key='detail' value='tab-1' :transition='false' :reverse-transition='false')
    v-form(v-model='valid' ref='form_all' v-if='all_sortList["1"] != undefined' )
        v-row.mb-4(no-gutters)
            v-col.pr-md-4(cols='12' :md='item.type=="txt" ? 12 : 6' v-for='(item,index) in all_sortList' :key="item.index").my-1
                p.d-flex.align-center.input-has-label.w-100(v-if='item.type=="bol"' :key='item.index')
                    label.ellipsis(style='width: 119px;' :title='item.label' ) {{item.label}}
                    v-checkbox.py-0.ml-auto(v-model='Object(all_orginalData)[item.id]' dense='dense' :hide-details='true' :readonly='all_isReadonly')
                        template(v-if='Editable' v-slot:append) 
                            v-icon.mr-1.mt-3(size='18' color="green" v-if='!all_isReadonly && !all_loading' @click='onConfirm()') icon-check
                            v-icon.mt-3(size='18' color="red" v-if='!all_isReadonly && !all_loading' @click='onCancel()') mdi-window-close  
                            v-icon.mt-3(size='18' v-if='all_isReadonly' @click='onEdit()') icon-edit
                            v-progress-circular(size='18' v-if='all_loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label(v-if='item.type=="dat"' :key='item.index')
                    label.ellipsis(style='width: 119px;' :title='item.label') {{item.label}} 
                    v-text-field.v-text-field_low_z-index(v-if='all_isReadonly' v-model='Object(all_orginalData)[item.id]' solo readonly )
                        template(v-if='Editable'  v-slot:append)
                            v-icon(size='18' v-if='all_isReadonly' @click='onEdit()') icon-edit
                    v-menu(v-else ref="menu" v-model="item.menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                        template(v-slot:activator="{ on, attrs }")
                            v-text-field.v-text-field_low_z-index(v-model='Object(all_orginalData)[item.id]' solo readonly :rules='item.mandatory && !all_isReadonly ? formRule.fieldRules: []' v-bind="attrs" v-on="on" clearable)
                                template(v-if='Editable'  v-slot:append)
                                    v-icon.mr-1(size='18' color="green" v-if='!all_isReadonly && !all_loading' @click='onConfirm()') icon-check
                                    v-icon(size='18' color="red" v-if='!all_isReadonly && !all_loading' @click='onCancel()') mdi-window-close  
                                    v-progress-circular(size='18' v-if='all_loading' indeterminate color='primary')
                        v-date-picker(v-model='Object(all_orginalData)[item.id]' @input='item.menu = false' no-title scrollable)
                p.d-flex.align-center.input-has-label(v-if='item.type=="dtt"' :key='item.index')
                    label.ellipsis(style='width: 119px;' :title='item.label') {{item.label}} 
                    v-text-field.v-text-field_low_z-index(v-if='all_isReadonly' v-model='Object(all_orginalData)[item.id]' solo readonly )
                        template(v-if='Editable'  v-slot:append)
                            v-icon(size='18' v-if='all_isReadonly' @click='onEdit()') icon-edit
                    v-menu(v-else ref="menu" v-model="item.menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                        template(v-slot:activator="{ on, attrs }")
                            v-text-field.v-text-field_low_z-index(v-model='Object(all_orginalData)[item.id]' solo readonly :rules='item.mandatory && !all_isReadonly ? formRule.fieldRules: []' v-bind="attrs" v-on="on" clearable @click='datepickerFocus(item)')
                                template(v-if='Editable'  v-slot:append)
                                    v-icon.mr-1(size='18' color="green" v-if='!all_isReadonly && !all_loading' @click='onConfirm()') icon-check
                                    v-icon(size='18' color="red" v-if='!all_isReadonly && !all_loading' @click='onCancel()') mdi-window-close  
                                    v-progress-circular(size='18' v-if='all_loading' indeterminate color='primary')
                        date-picker(v-model='Object(all_orginalData)[item.id]' type="datetime" valueType='format' inline :showSecond='false' format='YYYY-MM-DD HH:mm' @change="datepickerChange")
                p.d-flex.align-center.input-has-label(v-if='item.type=="str"' :key='item.index' )
                    label.ellipsis(style='width: 119px;' :title='item.label') {{item.label }}
                    v-text-field.v-text-field_low_z-index(v-model='Object(all_orginalData)[item.id]' solo='solo' :readonly='all_isReadonly' :rules='item.mandatory && !all_isReadonly ? formRule.fieldRules: []' )
                        template(v-if='Editable' v-slot:append)
                            v-icon.mr-1(size='18' color="green" v-if='!all_isReadonly && !all_loading' @click='onConfirm()') icon-check
                            v-icon(size='18' color="red" v-if='!all_isReadonly && !all_loading' @click='onCancel()') mdi-window-close  
                            v-icon(size='18' v-if='all_isReadonly' @click='onEdit()') icon-edit
                            v-progress-circular(size='18' v-if='all_loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label(v-if='item.type=="num"' :key='item.index')
                    label.ellipsis(style='width: 119px;' :title='item.label') {{item.label }}
                    v-text-field.v-text-field_low_z-index(v-model.number='Object(all_orginalData)[item.id]' solo='solo' :readonly='all_isReadonly' :rules='item.mandatory && !all_isReadonly ? formRule.numMandatoryRule: []' type='number' @input='Object(all_orginalData)[item.id] = check_num(Object(all_orginalData)[item.id])')
                        template(v-if='Editable' v-slot:append)
                            v-icon.mr-1(size='18' color="green" v-if='!all_isReadonly && !all_loading' @click='onConfirm()') icon-check
                            v-icon(size='18' color="red" v-if='!all_isReadonly && !all_loading' @click='onCancel()') mdi-window-close  
                            v-icon(size='18' v-if='all_isReadonly' @click='onEdit()') icon-edit
                            v-progress-circular(size='18' v-if='all_loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label(v-if='item.type=="opt"' :key='item.index')
                    label.ellipsis(style='width: 119px;' :title='item.label') {{item.label }}
                    v-autocomplete.mt-1(v-model='Object(all_orginalData)[item.id]'
                            :items="item.list",
                            item-text="value",
                            item-value="key", solo='solo' :readonly='all_isReadonly' 
                            :rules='item.mandatory && !all_isReadonly ? formRule.fieldRules: []'
                            :class=' all_isReadonly ? "remove-arrow" : ""' 
                            :menu-props='{closeOnClick:true}'
                            ) 
                        template(v-if='Editable' v-slot:append-outer)
                            v-icon.mr-1(size='18' color="green" v-if='!all_isReadonly && !all_loading' @click='onConfirm()') icon-check
                            v-icon(size='18' color="red" v-if='!all_isReadonly && !all_loading' @click='onCancel()') mdi-window-close  
                            v-icon(size='18' v-if='all_isReadonly' @click='onEdit()') icon-edit
                            v-progress-circular(size='18' v-if='all_loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label(v-if='item.type=="mlt"' :key='item.index')
                    label.ellipsis(style='width: 119px;' :title='item.label') {{item.label }}
                    v-autocomplete.mt-1(v-model='Object(all_orginalData)[item.id]'
                            :items="item.list",
                            item-text="value",
                            item-value="key", solo='solo' :readonly='all_isReadonly' 
                            :rules='item.mandatory && !all_isReadonly ? formRule.optMandatoryRule: []'
                            :class=' all_isReadonly ? "remove-arrow" : ""' 
                            :menu-props='{closeOnClick:true}'
                            multiple)
                        template(v-if='Editable' v-slot:append-outer)
                            v-icon.mr-1(size='18' color="green" v-if='!all_isReadonly && !all_loading' @click='onConfirm()') icon-check
                            v-icon(size='18' color="red" v-if='!all_isReadonly && !all_loading' @click='onCancel()') mdi-window-close  
                            v-icon(size='18' v-if='all_isReadonly' @click='onEdit()') icon-edit
                            v-progress-circular(size='18' v-if='all_loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label(v-if='item.type=="tst"' :key='item.index')
                    label.ellipsis(style='width: 119px;' :title='item.label') {{item.label }}
                    v-text-field.v-text-field_low_z-index.no-border(v-model.number='Object(all_orginalData)[item.id]' solo='solo' :readonly='all_isReadonly' :rules='item.mandatory && !all_isReadonly ? formRule.fieldRules: []')
                    treeselect.mt-1.pl-7(v-model='Object(all_orginalData)[item.id]'
                            :title='getTstTitle(item,Object(all_orginalData)[item.id])',
                            :options="item.list",
                            :multiple="false"
                            :disable-branch-nodes="true"
                            placeholder='' 
                            :clearable='!item.mandatory' 
                            noResultsText='No data available'
                            item-text="value",
                            item-value="key", solo='solo' 
                            :disabled='all_isReadonly' 
                            )
                        div(slot="option-label" slot-scope="{ node }" :title='node.raw.label') {{ node.raw.label }}
                        div(slot="value-label" slot-scope="{ node }") {{ node.raw.customLabel }}
                    v-icon.mr-1(size='18' color="green" v-if='Editable && !all_isReadonly && !all_loading' @click='onConfirm()') icon-check
                    v-icon(size='18' color="red" v-if='Editable && !all_isReadonly && !all_loading' @click='onCancel()') mdi-window-close  
                    v-icon(size='18' v-if='Editable && all_isReadonly' @click='onEdit()') icon-edit
                    v-progress-circular(size='18' v-if='Editable && all_loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label(v-if='item.type=="txt"' :key='item.index' )
                    label.ellipsis(style='width: 119px' :title='item.label') {{item.label}}
                    v-textarea.textarea-style(v-model='Object(all_orginalData)[item.id]' rows="3" row-height="20" solo no-resize dense='dense' :readonly='all_isReadonly' :rules='item.mandatory && !all_isReadonly ? formRule.fieldRules: []')
                        template(v-if='Editable' v-slot:append)
                            v-icon.mr-1.ml-1(size='18' color="green" v-if='!all_isReadonly && !all_loading' @click='onConfirm()') icon-check
                            v-icon(size='18' color="red" v-if='!all_isReadonly && !all_loading' @click='onCancel()') mdi-window-close  
                            v-icon.ml-1(size='18' v-if='all_isReadonly' @click='onEdit()') icon-edit     
                            v-progress-circular(size='18' v-if='all_loading' indeterminate color='primary')
    div.mt-7.mb-4.d-flex.align-center.justify-space-between
        h3.d-flex.align-center
            .cube-icon.small.bg-purple.mr-2
                v-icon(size='20' color='white') mdi-keyboard
            span {{$t('CUSTOMFIELD.TITLE')}}
    v-row.sheet-box(v-if='sortList["1"] === undefined ').mb-4
        v-col.d-flex.align-center.justify-center.pa-0( cols='12')
            v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                h5.ma-0.t-black {{$t('CUSTOMFIELD.NO_DATA')}}
    v-form(v-model='valid' ref='form')
        v-row.mb-4(no-gutters)
            v-col.pr-md-4(cols='12' :md='item.type=="txt" ? 12 : 6' v-for='(item,index) in sortList' :key="item.index").my-1
                p.d-flex.align-center.input-has-label.w-100(v-if='item.type=="bol"' :key='item.index')
                    label.ellipsis(style='width: 119px;' :title='item.label' ) {{item.label}}
                    v-checkbox.py-0.ml-auto(v-model='Object(orginalData)[item.id]' dense='dense' :hide-details='true' :readonly='isReadonly')
                        template(v-if='Editable' v-slot:append)
                            v-icon.mr-1.mt-3(size='18' color="green" v-if='!isReadonly && !loading' @click='onConfirm("common")') icon-check
                            v-icon.mt-3(size='18' color="red" v-if='!isReadonly && !loading' @click='onCancel("common")') mdi-window-close  
                            v-icon.mt-3(size='18' v-if='isReadonly' @click='onEdit("common")') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label(v-if='item.type=="dat"' :key='item.index')
                    label.ellipsis(style='width: 119px;' :title='item.label') {{item.label}} 
                    v-text-field.v-text-field_low_z-index(v-if='isReadonly' v-model='Object(orginalData)[item.id]' solo readonly)
                        template(v-if='Editable'  v-slot:append)
                            v-icon(size='18' v-if='isReadonly' @click='onEdit("common")') icon-edit
                    v-menu(v-else ref="menu" v-model="item.menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                        template(v-slot:activator="{ on, attrs }")
                            v-text-field.v-text-field_low_z-index(v-model='Object(orginalData)[item.id]' solo readonly :rules='item.mandatory && !isReadonly ? formRule.fieldRules: []' v-bind="attrs" v-on="on" clearable)
                                template(v-if='Editable'  v-slot:append)
                                    v-icon.mr-1(size='18' color="green" v-if='!isReadonly && !loading' @click='onConfirm("common")') icon-check
                                    v-icon(size='18' color="red" v-if='!isReadonly && !loading' @click='onCancel("common")') mdi-window-close  
                                    v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                        v-date-picker(v-model='Object(orginalData)[item.id]' @input='item.menu = false' no-title scrollable)
                p.d-flex.align-center.input-has-label(v-if='item.type=="dtt"' :key='item.index')
                    label.ellipsis(style='width: 119px;' :title='item.label') {{item.label}} 
                    v-text-field.v-text-field_low_z-index(v-if='isReadonly' v-model='Object(orginalData)[item.id]' solo readonly)
                        template(v-if='Editable'  v-slot:append)
                            v-icon(size='18' v-if='isReadonly' @click='onEdit("common")') icon-edit
                    v-menu(v-else ref="menu" v-model="item.menu" :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                        template(v-slot:activator="{ on, attrs }")
                            v-text-field.v-text-field_low_z-index(v-model='Object(orginalData)[item.id]' solo readonly :rules='item.mandatory && !isReadonly ? formRule.fieldRules: []' v-bind="attrs" v-on="on" clearable @click='datepickerFocus(item)')
                                template(v-if='Editable'  v-slot:append)
                                    v-icon.mr-1(size='18' color="green" v-if='!isReadonly && !loading' @click='onConfirm("common")') icon-check
                                    v-icon(size='18' color="red" v-if='!isReadonly && !loading' @click='onCancel("common")') mdi-window-close  
                                    v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                        date-picker.pb-2(v-model='Object(orginalData)[item.id]' type="datetime" valueType='format' inline :showSecond='false' format='YYYY-MM-DD HH:mm' @change="datepickerChange")
                p.d-flex.align-center.input-has-label(v-if='item.type=="str"' :key='item.index' )
                    label.ellipsis(style='width: 119px;' :title='item.label') {{item.label }}
                    v-text-field.v-text-field_low_z-index(v-model='Object(orginalData)[item.id]' solo='solo' :readonly='isReadonly' :rules='item.mandatory && !isReadonly ? formRule.fieldRules: []' )
                        template(v-if='Editable' v-slot:append)
                            v-icon.mr-1(size='18' color="green" v-if='!isReadonly && !loading' @click='onConfirm("common")') icon-check
                            v-icon(size='18' color="red" v-if='!isReadonly && !loading' @click='onCancel("common")') mdi-window-close  
                            v-icon(size='18' v-if='isReadonly' @click='onEdit("common")') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label(v-if='item.type=="num"' :key='item.index')
                    label.ellipsis(style='width: 119px;' :title='item.label') {{item.label }}
                    v-text-field.v-text-field_low_z-index(v-model='Object(orginalData)[item.id]' solo='solo' :readonly='isReadonly' :rules='item.mandatory && !isReadonly ? formRule.numMandatoryRule: []' type='number' @input='Object(orginalData)[item.id] = check_num(Object(orginalData)[item.id])')
                        template(v-if='Editable' v-slot:append)
                            v-icon.mr-1(size='18' color="green" v-if='!isReadonly && !loading' @click='onConfirm("common")') icon-check
                            v-icon(size='18' color="red" v-if='!isReadonly && !loading' @click='onCancel("common")') mdi-window-close  
                            v-icon(size='18' v-if='isReadonly' @click='onEdit("common")') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label(v-if='item.type=="opt"' :key='item.index')
                    label.ellipsis(style='width: 119px;' :title='item.label') {{item.label }}
                    v-autocomplete.v-text-field_low_z-index.mt-1(v-model='Object(orginalData)[item.id]'
                            :items="item.list",
                            item-text="value",
                            item-value="key", solo='solo' :readonly='isReadonly' 
                            :rules='item.mandatory && !isReadonly ? formRule.fieldRules: []'
                            :class=' isReadonly ? "remove-arrow" : ""' 
                            :menu-props='{closeOnClick:true}'
                            ) 
                        template(v-if='Editable' v-slot:append-outer)
                            v-icon.mr-1(size='18' color="green" v-if='!isReadonly && !loading' @click='onConfirm("common")') icon-check
                            v-icon(size='18' color="red" v-if='!isReadonly && !loading' @click='onCancel("common")') mdi-window-close  
                            v-icon(size='18' v-if='isReadonly' @click='onEdit("common")') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label(v-if='item.type=="mlt"' :key='item.index')
                    label.ellipsis(style='width: 119px;' :title='item.label') {{item.label }}
                    v-autocomplete.v-text-field_low_z-index.mt-1(v-model='Object(orginalData)[item.id]'
                            :items="item.list",
                            item-text="value",
                            item-value="key", solo='solo' :readonly='isReadonly' 
                            :rules='item.mandatory && !isReadonly ? formRule.optMandatoryRule: []'
                            :class=' isReadonly ? "remove-arrow" : ""' 
                            :menu-props='{closeOnClick:true}'
                            multiple) 
                        template(v-if='Editable' v-slot:append-outer)
                            v-icon.mr-1(size='18' color="green" v-if='!isReadonly && !loading' @click='onConfirm("common")') icon-check
                            v-icon(size='18' color="red" v-if='!isReadonly && !loading' @click='onCancel("common")') mdi-window-close  
                            v-icon(size='18' v-if='isReadonly' @click='onEdit("common")') icon-edit
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label(v-if='item.type=="tst"' :key='item.index')
                    label.ellipsis(style='width: 119px;' :title='item.label') {{item.label }}
                    v-text-field.v-text-field_low_z-index.no-border(v-model='Object(orginalData)[item.id]' solo='solo' :readonly='isReadonly' :rules='item.mandatory && !isReadonly ? formRule.fieldRules: []')
                    treeselect.mt-1.pl-7(v-model='Object(orginalData)[item.id]'
                            :title='getTstTitle(item,Object(orginalData)[item.id])',
                            :options="item.list",
                            :multiple="false"
                            :disable-branch-nodes="true"
                            placeholder='' 
                            :clearable='!item.mandatory' 
                            noResultsText='No data available'
                            item-text="value",
                            item-value="key", solo='solo' 
                            :disabled='isReadonly' 
                            ) 
                        div(slot="option-label" slot-scope="{ node }" :title='node.raw.label') {{ node.raw.label }}
                        div(slot="value-label" slot-scope="{ node }") {{ node.raw.customLabel }}

                    v-icon.mr-1(size='18' color="green" v-if='Editable && !isReadonly && !loading' @click='onConfirm("common")') icon-check
                    v-icon(size='18' color="red" v-if='Editable && !isReadonly && !loading' @click='onCancel("common")') mdi-window-close  
                    v-icon(size='18' v-if='Editable && isReadonly' @click='onEdit("common")') icon-edit
                    v-progress-circular(size='18' v-if='Editable && loading' indeterminate color='primary')
                p.d-flex.align-center.input-has-label(v-if='item.type=="txt"' :key='item.index' )
                    label.ellipsis(style='width: 119px' :title='item.label') {{item.label}}
                    v-textarea.v-text-field_low_z-index.textarea-style(v-model='Object(orginalData)[item.id]' rows="3" row-height="20" solo no-resize dense='dense' :readonly='isReadonly' :rules='item.mandatory && !isReadonly ? formRule.fieldRules: []')
                        template(v-if='Editable' v-slot:append)
                            v-icon.mr-1.ml-1(size='18' color="green" v-if='!isReadonly && !loading' @click='onConfirm("common")') icon-check
                            v-icon(size='18' color="red" v-if='!isReadonly && !loading' @click='onCancel("common")') mdi-window-close  
                            v-icon.ml-1(size='18' v-if='isReadonly' @click='onEdit("common")') icon-edit     
                            v-progress-circular(size='18' v-if='loading' indeterminate color='primary')
    v-dialog(v-model='messageDialog' width='360'  content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360'  content-class='statusDialog')
        success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360'  content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import CustomFieldDataService from "@/services/CustomFieldDataService";
    import TreeOptionsDataService from "@/services/TreeOptionsDataService";
    import messageDialog from '@/components/Dialog/messageDialog';
    import successDialog from '@/components/Dialog/successDialog';
    import errorDialog from '@/components/Dialog/errorDialog';
    import i18n from '/common/plugins/vue-i18n.js';
    import Treeselect from '@riophae/vue-treeselect';
    // import the styles
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';
    import '@/style/element/_treeselect.scss';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default Vue.extend({
        props: {
            Data: {
                required: true,
            },     
            DataClass :{
                type: String,
                required: true,
            },  
            Editable :{
                type: Boolean,
                required: false,
            },
            StageTypeId :{
                type: Number,
                required: false,
            },
        },
        components: {
            messageDialog,
            successDialog,
            errorDialog,
            Treeselect,
            DatePicker,
        },
        data() {
            return {
                loading: false,
                all_loading: false,
                dataValue:null,
                sortList:{},
                all_sortList:{},
                orginalData:null,
                all_orginalData:null,
                valid: false,    
                menu: false, 
                DataDefinition: null,
                all_DataDefinition: null,
                isReadonly: true, 
                isEdit: true,
                all_isReadonly: true, 
                all_isEdit: true,
                emptyData: {},
                all_emptyData: {},
                messageDialog: false,
                message: '',
                successDialog: false,
                errorDialog: false,
                errorMessage: '',
                formRule: {
                    fieldRules: [(v) => !!v || i18n.t('RULE.RULE_R')],
                    numMandatoryRule: [(v) => (v === 0 || !!v) || i18n.t('RULE.RULE_R')],
                    optMandatoryRule: [(v) => (v || []).length >0 || i18n.t('RULE.RULE_R')],
                },
                isEditable: true,
                oringal_data : null,
                cf_data : {},
                all_cf_data : {},
                have_cf:false,
                have_all_cf:false,
                process_array:[],
                cancelData:null,
                all_cancelData:null,
                treeSelectData:null,
                openDatePicker:null,
            }
        },
        async created(){
            await this.get_ts_list();
            await this.get_cf_detail();
        },
        methods:{
            getTstTitle(item,id){
                let treeSelectList = this.treeSelectData.filter(el=>el.id==item.tree_select_id)[0].option_set;
                return treeSelectList[id];
            },
            datepickerFocus(item){
                this.openDatePicker = item;
            },
            datepickerChange(value, type){
                if (type === 'minute') {
                    this.openDatePicker.menu = false;
                }
            },
            async get_ts_list(){
                await TreeOptionsDataService.list().then((response)=>{
                    this.treeSelectData = response.data;
                })
            },
            check_num(num){
                if(num === ""){
                    return null;
                }
                else{
                    return num;
                }
            },
            async get_cf_detail(){
                this.DataDefinition = null;
                this.sortList = {};
                this.all_sortList = {};
                this.orginalData = null;
                this.oringal_data = null;
                this.cf_data = {};
                this.all_cf_data = {};
                this.emptyData = {};
                this.process_array = [];
                this.have_cf = false;
                this.have_all_cf = false;
                if(this.StageTypeId)
                {
                    this.oringal_data = JSON.parse(JSON.stringify(this.Data));
                    let cf_list = this.oringal_data.filter(el => el.stage_type_id == this.StageTypeId || el.stage_type_id == "all");
                    if (cf_list.length > 1)
                    {
                        this.cf_data = this.oringal_data.filter(el => el.stage_type_id == this.StageTypeId)[0]['data'];
                        this.all_cf_data = this.oringal_data.filter(el => el.stage_type_id == 'all')[0]['data'];
                        this.have_cf = true;
                        this.have_all_cf = true;
                    }
                    else if(cf_list.length > 0){
                        if(cf_list[0].stage_type_id == 'all'){
                            this.all_cf_data = this.oringal_data.filter(el => el.stage_type_id == 'all')[0]['data'];
                            this.have_all_cf = true;
                        }else{
                            this.cf_data = this.oringal_data.filter(el => el.stage_type_id == this.StageTypeId)[0]['data'];
                            this.have_cf = true;
                        }
                    }
                    else{
                        this.cf_data = {};
                        this.have_cf = false;
                    }
                }else{
                    this.cf_data = this.Data;
                }
                await CustomFieldDataService.getCustomFieldDetail(this.DataClass)
                .then(response => {
                    if(this.StageTypeId)
                    {
                        for(let i in response.data[0].definition)
                        {
                            if(response.data[0].definition[i].stage_type_id==this.StageTypeId)
                            {
                                this.DataDefinition = response.data[0].definition[i].data;
                                this.process_array.push({'def':this.DataDefinition,'sort':{},'data':this.cf_data,'empty':{},'id':'common'});
                            }
                            if(response.data[0].definition[i].stage_type_id=='all')
                            {
                                this.all_DataDefinition = response.data[0].definition[i].data;
                                this.process_array.push({'def':this.all_DataDefinition,'sort':{},'data':this.all_cf_data,'empty':{},'id':'all'});
                            }
                        }
                    }else
                    {
                        this.DataDefinition = response.data[0].definition;
                        this.process_array.push({'def':this.DataDefinition,'sort':{},'data':this.cf_data,'empty':{},'id':'common'});
                    }
                    this.isEditable = this.Editable;
                }).catch(error => {
                    this.showErrorDialog(error);
                })
                
                for(let s in this.process_array)
                {
                    let def = this.process_array[s]['def'];
                    let sort = this.process_array[s]['sort'];
                    let data = this.process_array[s]['data'];
                    let empty = this.process_array[s]['empty'];
                    let pa_id = this.process_array[s]['id'];
                    for (let i in def){
                        let sequenceNumber=String(def[i]['sequence_number']);
                        Vue.set(sort, sequenceNumber ,def[i]);
                    }

                    for (let i in def){
                        let sequenceNumber=String(def[i]['sequence_number']);
                        Vue.set(sort[sequenceNumber], 'type', i.slice(0,3));
                        Vue.set(sort[sequenceNumber], 'id', i);
                        if(Object.keys(data).length != Object.keys(sort).length)
                        {
                            if(data[i] != null){
                                this.dataValue = data[i];
                            }else{
                                this.dataValue = null;
                                if(i.slice(0,3)==='bol')
                                {
                                    this.dataValue = false;
                                }
                            }    
                            Vue.set(empty, i ,this.dataValue);
                        }
                        if(i.slice(0,3) == "opt" || i.slice(0,3) == "mlt"){
                            let optList = Object.entries(sort[sequenceNumber].option_set).map((arr) => (
                                {
                                    key: arr[0],
                                    value: arr[1],
                                }));
                            Vue.set(sort[sequenceNumber], 'list', optList);
                        }
                        if(i.slice(0,3) == "tst"){
                            let optList = this.treeSelectData.filter(el=>el.id == def[i].tree_select_id)[0].value;
                            optList = this.kickOutChildren(optList);
                            Vue.set(sort[sequenceNumber], 'list', optList);
                        } 
                        if(i.slice(0,3) == "dat" || i.slice(0,3) == "dtt"){
                            Vue.set(sort[sequenceNumber], 'menu', false);
                        }
                    }
                    if(pa_id == 'common'){
                        let pa = this.process_array.filter(el => el.id =='common')[0];
                        this.DataDefinition = pa['def'];
                        this.sortList = pa['sort'];
                        this.cf_data = pa['data'];
                        this.emptyData = pa['empty'];
                    }else{
                        let pa = this.process_array.filter(el => el.id =='all')[0];
                        this.all_DataDefinition = pa['def'];
                        this.all_sortList = pa['sort'];
                        this.all_cf_data = pa['data'];
                        this.all_emptyData = pa['empty'];
                    }
                }
                if(Object.keys(this.emptyData).length > 0 ){
                    this.orginalData = this.emptyData;
                }
                else{
                    this.orginalData = this.cf_data ;
                }
                if(Object.keys(this.all_emptyData).length > 0 && this.have_all_cf){
                    this.all_orginalData = this.all_emptyData;
                }
                else{
                    this.all_orginalData = this.all_cf_data ;
                }
            },
            kickOutChildren(data){
                return data.map((el) => {
                        if(el.children && el.children.length>0){
                            return {
                                ...el,
                                children: this.kickOutChildren(el.children),
                            };
                        }else{
                            delete el.children;
                        }
                        return el;
                });
            },
            validateForm(mode){
                switch(mode){
                    case 'common':
                        if(this.$refs.form.validate()){
                            return true;
                        }
                        else{
                            return false;
                        }
                    default:
                        if(this.$refs.form_all.validate()){
                            return true;
                        }
                        else{
                            return false;
                        }
                }
            },
            onEdit(mode){
                if(mode == 'common'){
                    requestAnimationFrame(()=>{
                        this.$refs.form.resetValidation();
                    })
                    if(!this.all_isReadonly){
                        this.onConfirm('','common')
                    }else{
                        this.cancelData = JSON.parse(JSON.stringify(this.orginalData));
                        this.isEdit = false;
                        this.isReadonly = false;
                    }
                }
                else{
                    requestAnimationFrame(()=>{
                        this.$refs.form_all.resetValidation();
                    })
                    if(!this.isReadonly){
                        this.onConfirm('common','all')
                    }else{
                        this.all_cancelData = JSON.parse(JSON.stringify(this.all_orginalData));
                        this.all_isEdit = false;
                        this.all_isReadonly = false;
                    }
                }
            },
            async onConfirm(mode,open) {
                if (this.validateForm(mode)) {
                    if(mode=="common"){
                        this.loading = true;
                    }else{
                        this.all_loading = true;
                    }
                    let editData = {};
                    if(this.StageTypeId)
                    {
                        if(this.have_cf){
                            for(let i in this.oringal_data){
                                if(this.oringal_data[i].stage_type_id == this.StageTypeId){
                                    this.oringal_data[i].data = this.orginalData;
                                }
                            }
                        }
                        else{
                            this.oringal_data.push({"data":this.orginalData,"stage_type_id":this.StageTypeId});
                            this.have_cf = true;
                        }
                        if(this.have_all_cf){
                            for(let i in this.oringal_data){
                                if(this.oringal_data[i].stage_type_id == 'all'){
                                    this.oringal_data[i].data = this.all_orginalData;
                                }
                            }
                        }
                        else{
                            this.oringal_data.push({"data":this.all_orginalData,"stage_type_id":'all'});
                            this.have_all_cf = true;
                        }
                        editData = {"custom_field":this.oringal_data};
                    }else{
                        editData = {"custom_field":this.orginalData};
                    }
                    let edit_id = this.$route.params.id !=undefined ? this.$route.params.id : sessionStorage.getItem('page_id');

                    switch(this.DataClass){
                    case "Opportunity":
                        await CustomFieldDataService.updateOpptyCustomFieldDetail(edit_id,editData)
                        break;
                    case "Contact":
                        await CustomFieldDataService.updateContactCustomFieldDetail(edit_id,editData)
                        break;
                    case "Organization":
                        await CustomFieldDataService.updateOrgCustomFieldDetail(edit_id,editData)
                        break;
                    case "Case":
                        await CustomFieldDataService.updateCaseCustomFieldDetail(edit_id,editData)
                        break;
                    default:
                        break;
                    }
                    if(mode=="common"){
                        this.isEdit = true;
                        this.loading = false;
                        this.isReadonly = true;
                    }else{
                        this.all_isEdit = true;
                        this.all_loading = false;
                        this.all_isReadonly = true;
                    }
                    if(open=="common"){
                        this.isReadonly = false;
                    }else if(open=="all"){
                        this.all_isReadonly = false;
                    }
                }
            },
            onCancel(mode) {
                if(mode == 'common'){
                    this.orginalData = this.cancelData;
                    this.isEdit = true;
                    this.isReadonly = true;
                    requestAnimationFrame(()=>{
                        this.$refs.form.resetValidation();
                    })
                }else{
                    this.all_orginalData = this.all_cancelData;
                    this.all_isEdit= true;
                    this.all_isReadonly = true;
                    requestAnimationFrame(()=>{
                        this.$refs.form_all.resetValidation();
                    })
                }
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog() { 
                this.messageDialog = false;
            },
            showSuccessDialog() {
                this.successDialog = true;
            },
            onEmitSuccessDialog() {
                this.successDialog = false;
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorMessage = false;
            }
        },
        watch: {
            "Editable": function() {
                this.isEditable = this.Editable;
            },
            "StageTypeId": async function() {
                await this.get_cf_detail();
            },
            isReadonly(val){
                this.removeMenu(val);
            },
        }
    });
</script>

<style lang="css">
    .nopadding{
        padding-left: 0;
    }
</style>
<template lang='pug'>
    div
        v-row.sheet-box.my-5(v-if='cases.length == 0')
            v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                    h5.ma-0.t-black {{$t('CASETABLE.NO_CASE')}}
        .contact-detail-table.scroll-x.pb-0(v-else)
            .data-area(style='position: relative;')
                v-data-table.height_100.table_in_tab( :headers='dealsHeaders' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='cases' :items-per-page='itemPerPage' :page.sync='page' @page-count='pageCount = $event' multi-sort height='100%' hide-default-footer='hide-default-footer' :loading='isLoading' loading-text='loading...' style='padding-bottom: 45px')
                    template(v-slot:item.subject='{ item }')
                        v-row(no-gutters)
                            v-col(cols='2').d-none.d-md-flex
                                v-system-bar(:color='item.stage_light_color.color')
                            v-col(cols='12' md='10').ellipsis.d-flex.align-center
                                a.pl-2.ellipsis(:title='item.subject' :href='"/Case/" + item.id') {{ item.subject }}
                    template(v-slot:item.primary_contact.__chineseName__='{ item }')
                        .ellipsis
                            a.pl-2.ellipsis(:title='item.primary_contact.__chineseName__' :href='"/Contacts/" + item.primary_contact.id') {{ item.primary_contact.__chineseName__ }}
                    template(v-slot:item.priority_number='{ item }')
                        v-icon(v-if="item.priority_number == '5'" :title='item.priorityName' size='20' color='red') fas fa-angle-double-up
                        v-icon(v-if="item.priority_number == '4'" :title='item.priorityName' size='20' color='red') fas fa-angle-up
                        v-icon(v-if="item.priority_number == '3'" :title='item.priorityName' size='20' color='orange') fas fa-equals
                        v-icon(v-if="item.priority_number == '2'" :title='item.priorityName' size='20' color='primary') fas fa-angle-down
                        v-icon(v-if="item.priority_number == '1'" :title='item.priorityName' size='20' color='primary') fas fa-angle-double-down
                    template(v-slot:item.statusName='{ item }')
                        v-chip.my-2.text-white(v-if='item.statusName == $i18n.t("CASESTATUSLIST.RESOLVED")' color='green' small :title='item.statusName') {{ item.statusName }}
                        v-chip.my-2.text-white(v-else-if='item.statusName == $i18n.t("CASESTATUSLIST.CLOSED")' color='primary' small :title='item.statusName') {{ item.statusName }}
                        v-chip.my-2.text-black(v-else small :title='item.statusName') {{ item.statusName }}
                    template(v-slot:item.groupName='{ item }')
                        .ellipsis
                            span(v-if='item.group' :title='item.groupName') {{ item.groupName }}
                    template(v-slot:item.typeName='{ item }')
                        .ellipsis
                            span(v-if='item.type' :title='item.typeName') {{ item.typeName }}    
                    template(v-slot:item.owner.fullName='{ item }')
                        .ellipsis
                             a.pl-2.ellipsis(:href='onMail(item.owner.email)' :title='item.owner.fullName') {{ item.owner.fullName }}
                    template(v-slot:item.closed_date='{ item }')
                        .ellipsis
                            span(v-if='item.closed_date' :title='item.closed_date') {{ item.closed_date }}
                    template(v-slot:item.active='{ item }')
                        v-btn( icon='icon' @click='showToCaseDialog(item)')
                            v-icon(size='18') mdi-folder-wrench
                        v-btn(:disabled='item.status  == "closed"' icon='icon' @click='onDelete(item)')
                            v-icon(size='18') icon-remove
                v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10" style='position: absolute; bottom: 0; width: 100%; height: 40px')
        v-dialog(v-model='toCaseDialog' max-width='1200' v-if='toCaseDialog' persistent @keydown.esc='onEmitToCaseDialog(true)')
            to-case(@emitToCaseDialog='onEmitToCaseDialog' :case-data='caseData')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
</template>
<script>
import toCase from '@/components/Dialog/Case/toCase';
import messageDialog from '@/components/Dialog/messageDialog';
import CaseDataService from "@/services/CaseDataService";
import caseStatusList from "@/array/the_caseStatusList";
import casePriorityList from "@/array/the_casePriorityList";
import i18n from '/common/plugins/vue-i18n.js' 

export default { 
    props: {
        cases: {
            type: Array,
            require: true,
        },
        itemInPage: {
            type: Number,
            require: false,
        }
    },
    components: {
        toCase,
        messageDialog,
    },
    data() {
        return {
            message:'',
            msgDialog_mode:'',
            messageDialog:false,
            toCaseDialog:false,
            page: 1,
            pageCount: 0,
            itemPerPage: this.itemInPage ? this.itemInPage : 10,            
            dealsHeaders: [
                {
                    text: i18n.t('CASETABLE.SUBJECT'),
                    value: 'subject',
                    // width: '14%',
                    align: 'center'
                },
                {
                    text: i18n.t('CASETABLE.CONTACT'),
                    value: 'primary_contact.__chineseName__',
                    // width: '13%',
                    align: 'center'
                },
                {
                    text: i18n.t('CASETABLE.PRIORITY'),
                    value: 'priority_number',
                    // width: '11%',
                    align: 'center',
                },
                {
                    text: i18n.t('CASETABLE.STATUS'),
                    value: 'statusName',
                    // width: '10%',
                    align: 'center'
                },
                {
                    text: i18n.t('CASETABLE.GROUP'),
                    value: 'groupName',
                    // width: '10%',
                    align: 'center',
                },
                {
                    text: i18n.t('CASETABLE.TYPE'),
                    value: 'typeName',
                    // width: '15%',
                    align: 'center'
                },
                {
                    text: i18n.t('CASETABLE.OWNER'),
                    value: 'owner.fullName',
                    // width: '11%',
                    align: 'center',
                },
                {
                    text: i18n.t('CASETABLE.CLOSED_DATE'),
                    value: 'closed_date',
                    // width: '11%',
                    align: 'center',
                },
                {
                    text: i18n.t('GENERAL.ACTIVE'),
                    value: 'active',
                    align: 'center',
                    width: '130',
                    sortable: false,
                }
            ],
            caseStatusList: caseStatusList,
            casePriorityList: casePriorityList,
            caseGroupList: [],
            caseTypeList: [],
            isLoading: true
        }
    },
    async created(){
        await CaseDataService.getcasegrouplist()
        .then(response => {
            this.caseGroupList = response.data;
        });

        await CaseDataService.getcasetypelist()
        .then(response => {
            this.caseTypeList = response.data;
        });

        await this.cases.forEach(caseItem => {
            caseItem.priorityName = this.getItemNameFromList(this.casePriorityList, caseItem.priority);
            caseItem.priority_number = this.getPriorityNumber(caseItem.priority);
            caseItem.statusName = this.getItemNameFromList(this.caseStatusList, caseItem.status);
            caseItem.groupName = this.getItemFromListById(this.caseGroupList, caseItem.group).group_name;
            caseItem.typeName = this.getItemFromListById(this.caseTypeList, caseItem.type).type_name;
            caseItem.owner.fullName = caseItem.owner.first_name != '' ? caseItem.owner.last_name + ' ' + caseItem.owner.first_name : caseItem.owner.last_name;
            if (caseItem.closed_date != null) {
                caseItem.closed_date = this.getFormatTime(new Date(caseItem.closed_date));
            }
        })
        await(this.isLoading = false);
    },
    methods: {
        showToCaseDialog(item){
            this.caseData = item
            this.toCaseDialog = true
        },
        onEmitToCaseDialog(data) {
            if(data.id)
            {
                this.caseData.subject = data.subject
                this.caseData.primary_contact = data.primary_contact
                this.caseData.due_date = data.due_date
                this.caseData.priority = data.priority
                this.caseData.priority_number = this.getPriorityNumber(data.priority)
                this.caseData.status = data.status
            }			
            this.toCaseDialog = false;
        },
        onDelete (item) {
                this.deleteItem = item;
                this.msgDialog_mode = 'delete';
                this.showMessageDialog(i18n.t('CASETABLE.MESSAGE'));
            },
        showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
        onEmitMessageDialog(val) {
                this.messageDialog = false;
                if (val) { 
                    // 如果messageDialog是在刪除時被觸發
                    if(this.msgDialog_mode == 'delete'){
                        CaseDataService.delete(this.deleteItem.id)
                        .then(() => {							
                            let index = this.cases.indexOf(this.deleteItem);
                            this.cases.splice(index, 1);							
                            this.successDialog = true; 
                            this.msgDialog_mode = '';
                        })
                        .catch(response => {
                            this.showErrorDialog(response)
                        })
                    }else{
                        this.successDialog = true; 
                    }
                    
                }
            },
        onMail: function (mail) {
            return 'mailto:'+mail;
        },
        getFormatTime(data){
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
            let today  = data;
            if(this.$i18n.locale == "zh_TW")
            {
                return today.toLocaleDateString("zh-TW", options); 
            }else
            {
                return today.toLocaleDateString("en-US", options);
            }                
        },
    },
    watch:{
        "cases": async function (){
            this.isLoading = true;
            if(this.cases.length>0){
                await this.cases.forEach(caseItem => {
                    caseItem.priorityName = this.getItemNameFromList(this.casePriorityList, caseItem.priority);
                    caseItem.statusName = this.getItemNameFromList(this.caseStatusList, caseItem.status);
                    caseItem.groupName = this.getItemFromListById(this.caseGroupList, caseItem.group).group_name;
                    caseItem.typeName = this.getItemFromListById(this.caseTypeList, caseItem.type).type_name;
                    caseItem.owner.fullName = caseItem.owner.first_name != '' ? caseItem.owner.last_name + ' ' + caseItem.owner.first_name : caseItem.owner.last_name;
                    if (caseItem.closed_date != null) {
                        caseItem.closed_date = this.getFormatTime(new Date(caseItem.closed_date));
                    }
                });
            }
            await (this.isLoading = false);
        }
    }
}
</script>